
import { defineComponent } from "vue";
import BasicLayout from "@/layout/BasicLayout.vue";
import WellDoneBlock from "@/views/Onboarding/WellDoneBlock.vue";
import router from "@/router";
import MobileTheoryBlock from "@/views/Onboarding/MobileTheoryBlock.vue";

export default defineComponent({
  name: "MoreInformationDone",
  components: { MobileTheoryBlock, WellDoneBlock, BasicLayout },
  setup() {
    const onClick = () => {
      router.push({ name: "BasicDashboard" });
    };
    return {
      onClick,
    };
  },
});
